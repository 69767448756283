import React, { useEffect, useState } from 'react';
import { useQuery } from 'utils/react-query';
import { InView } from 'react-intersection-observer';

import Modal from 'components/uiLibrary/Modal';
import { PrimaryButton, QuaternaryButton } from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Loader from 'components/uiLibrary/Loader';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';
import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';

import queries, { useUpdateRegistrationMutation } from 'containers/Accounts/queries';
import { REGISTRATION_STEPS } from 'containers/Accounts/constants';
import { useTranslation, Trans } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { errorMessageConverter } from 'utils/common';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

import {
  useUserData,
  useAccessPendingProfileIds,
  useUserProfiles,
  useRefetchUserQueries,
  useProfileAccesses,
} from 'utils/hooks/useAuthenticatedUser';

import { TP, BASE_PAGE_ROUTES } from 'constants/index';
import useAppContext from 'utils/hooks/useAppContext';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';

import classes from './EditDisclaimerModal.module.scss';

const policyConfig = [
  {
    serialNo: 1,
    title: `DISCLAIMER_SR_TITLE_1`,
    description: `DISCLAIMER_SR_DISC_1`,
  },
  {
    serialNo: 2,
    title: `DISCLAIMER_SR_TITLE_2`,
    description: `DISCLAIMER_SR_DESC_2`,
  },
  {
    serialNo: 3,
    title: `DISCLAIMER_SR_TITLE_3`,
    description: `DISCLAIMER_SR_DESC_3`,
  },
  {
    serialNo: 4,
    title: `DISCLAIMER_SR_TITLE_4`,
    description: `DISCLAIMER_SR_DESC_4`,
  },
  {
    serialNo: 5,
    title: `DISCLAIMER_SR_TITLE_5`,
    description: `DISCLAIMER_SR_DESC_5`,
  },
  {
    serialNo: 6,
    title: `DISCLAIMER_SR_TITLE_6`,
    description: `DISCLAIMER_SR_DESC_6`,
  },
];

const ConfirmationPolicyContent = ({ confirmPolicyHandler, t, activeRegistration, isLoading, isReloading }) => {
  const { entities } = activeRegistration;
  const trackingData = { section: SECTIONS.DISCLAIMER };

  if (isReloading) {
    return (
      <div className={classes.loadingRedirect}>
        <Loader helpText />
      </div>
    );
  }
  return (
    <div className={classes.confirmationPolicy}>
      <div className={classes.confirmationPolicy__content}>
        <Typography size="14" variant="p">
          <Trans
            ns="NS_ENTITY_STUB_PAGE"
            i18nKey="ACCEPTED_TERMS_WTIH_PROFILE_NAME"
            components={{
              profileName: entities?.profile?.name,
              b: <b />,
            }}
          />
        </Typography>
        <br />
        <Typography size="14" variant="p">
          {t(`${TP}.CONFIRMATION_CONTENT_NOTE`)}
        </Typography>
      </div>
      <div>
        <div className={classes.confirmationPolicy__footer}>
          <div className={classes.confirmationPolicy__footer__actions}>
            <PrimaryButton
              styles={{ root: classes.confirmationPolicy__footer_btn }}
              variant="primary"
              shape="rectangle"
              rightIcon={<SpriteIcon icon="chevron_right" />}
              onClick={confirmPolicyHandler}
              disabled={isLoading}
              isLoading={isLoading}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.CONFIRM_CTA,
              }}
            >
              {t(`${TP}.FN_PROCEED`)}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const DisclaimerPolicyContent = ({ setIsScrolledToBottom, enableButton, handleAccept, handleDecline, isLoading }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const trackingData = { section: SECTIONS.DISCLAIMER };

  return (
    <>
      <div className={classes.editDisclaimerModal__main} title={t('DISCLAIMER_TOOLTIP_TITLE')}>
        <div className={classes.editDisclaimerModal__scrollMsg}>
          <Typography size="12" italic className={classes.editDisclaimerModal__text}>
            {t(`${TP}.SCROLL_PRAVICY_BELOW_TITLE`)}
          </Typography>
        </div>
        <div className={classes.editDisclaimerModal__policyList}>
          {policyConfig.map(policy => (
            <div className={classes.editDisclaimerModal__policyListItem} key={policy.serialNo}>
              <Typography size="14" variant="p" weight="medium" className={classes.editDisclaimerModal__policyTitle}>
                {policy.serialNo}. {t(policy.title)}
              </Typography>
              <Typography size="12" variant="p" className={classes.editDisclaimerModal__policyDesc}>
                {t(policy.description)}
              </Typography>
            </div>
          ))}

          <Typography variant="p" size="12">
            {t(`${TP}.ACCEPT_CLICK_BLOW_ACKNOWLEDGE`)}
          </Typography>
          <InView
            onChange={inView => {
              if (inView) {
                setIsScrolledToBottom(true);
              }
            }}
          >
            <Typography variant="p" size="12" className={classes.editDisclaimerModal__mainAddress}>
              Arts Consolidated APS
              <br />
              Lyngbyvej 20
              <br />
              2100 Copenhagen Ø Denmark
              <br />
              Company registration no. 35529160
            </Typography>
          </InView>
        </div>
      </div>
      <div className={classes.editDisclaimerModal__footer}>
        {!enableButton && (
          <Typography size="12" italic className={classes.editDisclaimerModal__text}>
            {t(`${TP}.SCROLL_PRAVICY_BELOW_TITLE`)}
          </Typography>
        )}
        <div className={classes.editDisclaimerModal__footer__actions}>
          <QuaternaryButton
            variant="text"
            disabled={!enableButton}
            onClick={handleDecline}
            isLoading={isLoading}
            trackingData={{
              ...trackingData,
              component: COMPONENTS.DECLINE_CTA,
            }}
          >
            {t(`${TP}.m_DECLINE`)}
          </QuaternaryButton>
          <PrimaryButton
            variant="primary"
            onClick={handleAccept}
            shape="rectangle"
            isLoading={isLoading}
            leftIcon={<SpriteIcon icon="check_circle" />}
            disabled={!enableButton}
            trackingData={{
              ...trackingData,
              component: COMPONENTS.ACCEPT_CTA,
            }}
          >
            {t(`${TP}.ACCEPT_ABOVE_TERMS_CONDITIONS`)}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

const EditDisclaimerModal = ({ profileId }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isLoggedIn } = useAppContext();
  const profileIdsPendingAccess = useAccessPendingProfileIds();
  const userProfiles = useUserProfiles();
  const refreshUserQueries = useRefetchUserQueries();
  const artistProfiles = userProfiles;
  const { entity, entityType, navigate } = usePageContext();
  const { isArtistInRoster } = useEntityEditPermission({ entityType, entity });
  const { profileData } = useGetTargetProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const userData = useUserData();
  const { data: profileAccesses } = useProfileAccesses();
  const isOwnerProfile = profileAccesses?.data?.some(
    profile => profile?.isOwner && entity?.id === profile?.profile?.id,
  );
  const hasProfileAccess = profileAccesses?.data?.some(access => access?.profile?.id === profileId);

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);

  const {
    isEditDisclaimerModal,
    setIsEditDisclaimerModal,
    setIsAlreadyClaimedModal,
    isPreviewModalOpen,
    setIsArtistNoAccessModalOpen,
  } = useDialogs();
  const { setSubscriptionNudgesPopup } = useNotificationPopup();

  const { data: registrations, refetch: refetchRegistrations } = useQuery(
    queries.getRegistrations({ userId: userData?.id, queryConfig: { enabled: !!userData?.id } }),
  );
  useEffect(() => {
    if (userData?.id) {
      refetchRegistrations();
    }
  }, [refetchRegistrations, userData?.id]);

  const activeRegistration = registrations?.data?.find(reg => reg?.entities?.profile?.id === profileId);

  const { mutate: updateRegistrationMutation } = useUpdateRegistrationMutation({
    onSuccess: (res, payload) => {
      const updatedRegistration = res?.data;
      const { has_accepted_disclaimer, has_confirmed_disclaimer } = payload?.status || {};

      const handleDisclaimerActions = () => {
        if (has_accepted_disclaimer) {
          setIsDisclaimerAccepted(true);
        } else if (has_confirmed_disclaimer) {
          setIsDisclaimerAccepted(false);
          setIsEditDisclaimerModal(false);
        }
      };
      const handleNoAccessCheck = async () => {
        const hasDateOfDeath =
          entity?.personal?.death?.day || entity?.personal?.death?.month || entity?.personal?.death?.year;
        const isProfilePendingAccess = entity?.accesses?.length > 0 && !isOwnerProfile;
        const showNoAccessForm =
          (userProfiles?.length > 1 || !!hasDateOfDeath) &&
          !isProfilePendingAccess &&
          profileIdsPendingAccess.includes(entity?.id);

        if (isProfilePendingAccess) {
          setIsEditDisclaimerModal(false);
          setIsAlreadyClaimedModal(true);
        } else if (showNoAccessForm) {
          setIsEditDisclaimerModal(false);
          setIsArtistNoAccessModalOpen(true);
        } else {
          try {
            setIsLoading(true);
            setIsReloading(true);
            await refreshUserQueries();
            setIsEditDisclaimerModal(false);
            setIsLoading(false);
            setIsReloading(false);
            navigate.to(
              navigate.getLinkProps({
                entityType,
                entity,
                baseRoute: BASE_PAGE_ROUTES.PROFILE,
                edit: true,
              }),
            );
          } catch (error) {
            setIsLoading(false);
            setIsReloading(false);
            console.error('Error while refreshing user queries:', error);
          }
        }
      };

      handleDisclaimerActions();
      refetchRegistrations();

      if (updatedRegistration?.status?.has_confirmed_disclaimer) {
        handleNoAccessCheck();
      }

      setIsLoading(false);
    },
    onError: err => {
      enqueueSnackbar(errorMessageConverter(err), { variant: SNACKBAR_VARIANTS.ERROR });
      const { status, step, id } = activeRegistration || {};
      const { has_accepted_disclaimer, has_seen_disclaimer, has_confirmed_disclaimer } = status || {};
      sentryCaptureMessage('Error on disclaimer modal', {
        extra: {
          error: err,
          context: {
            step,
            id,
            has_accepted_disclaimer,
            has_seen_disclaimer,
            has_confirmed_disclaimer,
          },
        },
      });
    },
  });

  const isLoggedInArtist = isLoggedIn && profileData;

  useEffect(() => {
    const { status, step } = activeRegistration || {};

    const { has_accepted_disclaimer, has_seen_disclaimer, has_confirmed_disclaimer } = status || {};
    const isProfilePendingAccess = entity?.accesses?.length > 0 && profileIdsPendingAccess.includes(entity?.id);
    const shouldShowEditDisclaimerModal =
      step >= REGISTRATION_STEPS.subscriptionForm && !has_accepted_disclaimer && !has_seen_disclaimer;
    const shouldShowAlreadyClaimedModal = isArtistInRoster
      ? isProfilePendingAccess
      : has_accepted_disclaimer &&
        isProfilePendingAccess &&
        has_confirmed_disclaimer &&
        !userData?.isSuspicious &&
        ![REGISTRATION_STEPS.noAccessForm, REGISTRATION_STEPS.submitNoAccessForm].includes(step) &&
        isProfilePendingAccess;
    const shouldShowDisclaimerAccepted = has_accepted_disclaimer && !has_confirmed_disclaimer;
    const shouldShowNoAccessFormModal =
      [REGISTRATION_STEPS.noAccessForm, REGISTRATION_STEPS.submitNoAccessForm].includes(step) &&
      !userData?.isSuspicious &&
      !isOwnerProfile &&
      profileIdsPendingAccess.includes(entity?.id);
    if (shouldShowEditDisclaimerModal) {
      setIsEditDisclaimerModal(true);
    } else if (shouldShowAlreadyClaimedModal) {
      setIsAlreadyClaimedModal(true);
    } else if (shouldShowDisclaimerAccepted) {
      setIsEditDisclaimerModal(true);
      setIsDisclaimerAccepted(true);
    } else if (shouldShowNoAccessFormModal) {
      setIsArtistNoAccessModalOpen(true);
    }
  }, [
    profileId,
    activeRegistration,
    isLoggedInArtist,
    profileIdsPendingAccess,
    setIsEditDisclaimerModal,
    setSubscriptionNudgesPopup,
    setIsAlreadyClaimedModal,
    isReloading,
    artistProfiles.length,
    isPreviewModalOpen,
    setIsArtistNoAccessModalOpen,
    isArtistInRoster,
    userData?.isSuspicious,
    userData.id,
    hasProfileAccess,
    entity?.accesses?.length,
    isOwnerProfile,
    entity?.id,
  ]);
  const confirmPolicyHandler = () => {
    setIsLoading(true);
    const hasDateOfDeath =
      entity?.personal?.death?.day || entity?.personal?.death?.month || entity?.personal?.death?.year;
    const isProfilePendingAccess = entity?.accesses?.length > 0;
    const showNoAccessForm =
      (artistProfiles?.length > 1 || !!hasDateOfDeath) &&
      !isProfilePendingAccess &&
      profileIdsPendingAccess.includes(entity?.id);
    const payload = {
      ...activeRegistration,
      step: showNoAccessForm ? REGISTRATION_STEPS.noAccessForm : REGISTRATION_STEPS.subscriptionForm,
      status: {
        ...activeRegistration?.status,
        has_confirmed_disclaimer: true,
      },
    };
    updateRegistrationMutation(payload);
  };
  const handleDecline = () => {
    setIsEditDisclaimerModal(false);
  };
  const handleAccept = () => {
    setIsLoading(true);
    const payload = {
      ...activeRegistration,
      step: REGISTRATION_STEPS.subscriptionForm,
      status: {
        ...activeRegistration?.status,
        has_accepted_disclaimer: true,
        has_seen_disclaimer: true,
      },
    };
    updateRegistrationMutation(payload);
  };

  const mainContent = !isDisclaimerAccepted ? (
    <DisclaimerPolicyContent
      enableButton={isScrolledToBottom}
      handleAccept={handleAccept}
      handleDecline={handleDecline}
      isLoading={isLoading}
      setIsScrolledToBottom={setIsScrolledToBottom}
      t={t}
    />
  ) : (
    <ConfirmationPolicyContent
      t={t}
      confirmPolicyHandler={confirmPolicyHandler}
      activeRegistration={activeRegistration}
      isLoading={isLoading}
      isReloading={isReloading}
    />
  );

  if (!hasProfileAccess && !profileIdsPendingAccess.length) {
    return null;
  }

  return (
    <Modal
      isOpen={isEditDisclaimerModal}
      disabledCloseHandler
      className={!isDisclaimerAccepted ? classes.drawerContainer : ''}
      icon={isDisclaimerAccepted ? '' : 'warning'}
      styles={{
        modalContainer: classes.editDisclaimerModal,
        modalRoot: classes.editDisclaimerModal__modalRoot,
        title: classes.editDisclaimerModal__title,
        closeIcon: classes.editDisclaimerModal__closeIcon,
        icon: classes.editDisclaimerModal__icon,
      }}
      allowMobileDrawer
      title={isDisclaimerAccepted ? t(`${TP}.CONFIRMATION_OF_ACCEPTED_TERMS`) : t(`${TP}.DISCLAIMER_EDIT_POPUP_TITLE`)}
    >
      {!isLoading ? (
        mainContent
      ) : (
        <div className={classes.loadingRedirect}>
          <Loader helpText />
        </div>
      )}
    </Modal>
  );
};

export default EditDisclaimerModal;
